
/**
 * @name: thematicAdd
 * @author: Gzm
 * @date: 2023-06-02 10:39
 * @description：thematicAdd
 * @update: 2023-06-02 10:39
 */
import {Component, Vue} from "vue-property-decorator";
import {IProduct} from "@/apis/product/types";
import {Message} from "element-ui";
import config from "@/config";
import Basic from "./components/Basic.vue"
import Details from "./components/Details.vue"
import {createCourseApi, getCourseDetailApi, modifyCourseApi,} from "@/apis/coursesManage/courses";
import {CourseTypes, ICourse} from "@/apis/coursesManage/courses/types";
import router from "@/router";
import {nextTick} from "vue";
import {
  thematicActivitiesCreateApi,
  thematicActivitiesDetailApi,
  thematicActivitiesModifyApi
} from "@/apis/marketingManage/thematicActivities";
import {IThematicQuery} from "@/apis/marketingManage/thematicActivities/types";

@Component({
  components: {Basic, Details}
})
export default class ProductAddPage extends Vue {

  config = config;
  //tab
  activeTabsName = 'first';
  id = ''
  // 新增修改表单
  modelForm: IThematicQuery = {
    status: 1
  }

  rules: any = {
    activitiesName: [
      {required: true, message: '请输入专题活动', trigger: 'blur'}
    ],
    reservePrice: [
      {required: true, message: '请输入预定金额', trigger: 'blur'}
    ],
    enrollQty: [
      {required: true, message: '请输入报名名额', trigger: 'blur'}
    ],
    coverPic: [
      {required: true, message: '请上传封面图片', trigger: 'blur'}
    ],
    activitiesBannerPic: [
      {required: true, message: '请上传赛事轮播图', trigger: 'blur'}
    ],
    activitiesVideo: [
      {required: true, message: '请上传赛事视频', trigger: 'blur'}
    ],
    sort: [
      {required: true, message: '请选择排序', trigger: 'change'}
    ],
    contestId: [
      {required: false, message: '请选择活动', trigger: 'change'}
    ],
    linkActivityType: [
      {required: true, message: '请选择活动类型', trigger: 'change'}
    ],
  }


  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * tab
   * @param tab
   * @param event
   */
  handleTabsClick(tab: any, event: any) {
  }

  /**
   * submitFinish
   *
   * @param event
   */
  submitFinish(form: any, event: any) {
    this.modelForm = Object.assign(this.modelForm, form);
    // @ts-ignore
    this.$refs.basicRef.$refs.modelForm.validate(async valid => {
      if (valid) {
        if (this.modelForm.id) {
          thematicActivitiesModifyApi(this.modelForm).then(e => {
            if (e) {
              Message.success('编辑成功！')
              this.handelBack()
            }
          })
        } else {
          thematicActivitiesCreateApi(this.modelForm).then(e => {
            if (e) {
              Message.success('新增成功！')
              this.handelBack()
            }
          })
        }
      }
    })
  }

  getDetail() {
    if (!this.modelForm.id)
      return
    thematicActivitiesDetailApi(this.modelForm.id).then(e => {
      this.modelForm = e;
    })
  }

  /**
   * 商品详情信息,商品规格信息,商品基础信息
   * @param form
   */
  handleNext(form: IProduct, tab: string) {
    this.modelForm = Object.assign(this.modelForm, form);
    this.activeTabsName = tab;
  }

  handelBack() {
    this.$store.dispatch('delView', {path: '/marketingManage/thematicAdd'});
    router.push({path: '/marketingManage/thematicActivities'})
  }

  async created() {
    const {id} = this.$route.query;
    this.modelForm.id = id;
    await this.getDetail();
  }
}
